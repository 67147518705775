.activation {
  max-width: 350px;
  width:100%;
  height: auto;
}

code {
  padding: 5px 10px;
  display: inline-block;
}

.container-a {
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: max-content;
  grid-column-gap: 10px;
  justify-content: center;
  position:relative;
  margin: 164px 0;
}

.field-a {
  width: 6.5vw;
  height: 6.5vw;
  max-width: 90px;
  max-height: 90px;
  font-size: 40px;
  text-align: center;
  outline: none;
  border-radius: 5px;
  border: 1px solid gray;
  transition-property: color, border, box-shadow, transform;
  transition-duration: 250ms;
}
.field-a:focus {
    outline: none;
    box-shadow: 0 0 7px rgba(blue, 0.5);
    border: 1px solid blue;
    transform: scale(1.05);
}


.field-a-complete {
  border: 1px solid green;
  color: green;
}
.field-a-complete [disabled] {
    background: rgba(green, 0.1);
    opacity: 0.5;
    cursor: not-allowed;
  
}

html, body, #root, #root>div {
  height: 100%
}

.styledButton {
  border-radius: 50px !important;
  width: 250px;
  height: 50px;
  font-size: 20px !important;
}

.qr-container {
  position: relative;
  border: 4px solid rgb(24,64,183);
  height: 500px;
  border-radius: 5px;
}

.qr-text {
  position: absolute;
  background-color: #fffdf6!important;
  padding: 0px 4vw;
}

.qr-text h3 {
  color:  rgb(24,64,183);
  font: 400 25px Arial;
}

.qs-container {
  position: relative;
  top: 100px;
  margin-top: 50px;
}

.loading-container {
  /* background-image: url('./bg_vera_module.png'); */
  height: 200px;
  box-shadow: 0px 0px 3px 3px lightgray;
  border-radius: 5px;


}

.qr-container-int{
  border-radius: 5px;
  background-color: #fffdf6!important;
}


.qr-root {
  width: 100%;
}

.vera-icon {
  margin-top: 100px;
  margin-bottom:10px;
}

@media only screen and (max-width: 1030px){
  .field-a {
    font-size: 30px;
  }
}

@media only screen and (max-width: 750px){
  .activation{
    max-width: 300px;
    width:100%;
    height: auto;
  }

  .field-a {
    font-size: 20px;
    /* width: 20px;
    height: 20px; */
    width: 4.5vw;
    height: 4.5vw;
  }
  
  .styledButton {
    width: 160px;
    height: 40px;
    color: red;
    font-size: 15px !important;
  }
  
  .qr-container {
    height: 420px;
  }

  .container-a {
    grid-column-gap: 5px;
  }

  .qr-text h3 {
    font-size:20px;
  }
}